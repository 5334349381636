* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.transition {
  transition: all 1s ease;
}

.link {
  position: relative;
  z-index: 1;
}

.fa-moon {
  font-size: x-large;
  margin: 0px;
  color: black;
  transition: all 12s ease;
}

.fa-sun {
  font-size: x-large;
  margin: 0px;
  color: white;
  transition: all 12s ease;
}

.logo {
  color: black;
  transition: all 1s ease;
  letter-spacing: 3px;
  font-size: 20px;
  float: left;
  font-weight: 600;
}

.nav-link {
  transition: all 1s ease;
}

@media screen and (max-width: 768px) {
  .fa-moon {
    font-size: 1.4rem;
    margin-right: 30px;
    color: black;
    transition: all 12s ease;
  }

  .fa-sun {
    font-size: 1.4rem;
    color: white;
    margin-right: 30px;
    transition: all 12s ease;
  }
}
