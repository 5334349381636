.phoneText{
  display: none;
}

.status{
  float: right;
}

.notification{
  transition: all 1s ease;
  font-size: 2rem;
  color: #FFD523;
}

.contact-button{
  border-radius: 20px !important;
  width: 12rem;
}

@media screen and (max-width: 520px) {
  .pcText{
    display: none;
  }

  .contactForm{
    margin-top: 5%;
    border-radius: 15px;
    padding: 5%;
  }
}

@media screen and (max-width: 600px) {
  .phoneText{
    display: block;
  }

  .pcText{
    display: none;
  }

  .contact-button{
    border-radius: 20px !important;
    width: 12rem;
    /* margin: auto 0px auto 0px; */
  }

  .btn-div{
    /* text-align: center; */
  }
}
