.envelope{
  background-color: white;
  margin: 0px auto 0px auto;
  padding: 20px 0px 0px 0px;
  justify-content: space-between;
  transition: all 1s ease;
}

.envelopedBlack{
  background-color: #212529;
  margin: 0px auto 0px auto;
  padding: 20px 0px 0px 0px;
  justify-content: space-between;
  transition: all 1s ease;
}

.conButtonsPc{
  margin-top: 20%;
}

.conButtons{
  display: none;
}

.whiteText{
  color: white;
  transition: all 1s ease;
}

.blackText{
  color: #343a40;
  transition: all 1s ease;
}

.welcomeText{
  /* margin-bottom: 3rem; */
}

.devGuy img{
  width: 95%;
  padding-top: 6%;
}

.btn-light{
  border-radius: 20px !important;
  width: 12rem;
}

.btn-intro{
  margin-left: 30px;
  border-radius: 20px !important;
  width: 12rem;
}

.intro{
  font-family: "poppins", "sans-serif";
  color: black;
  padding-top: 5%
} 

.intro h3{
  /* width: 100%; */
  font-weight: 600;
  font-size: 30px;
}

.name{
  color:#FFD523;
  transition: opacity 1s ease-in
}

.fadeIn{
  opacity: 0;
}

.fadeOut{
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .intro {
    text-align: center;
    padding-top: 2%
  }
  .description {
    display: none;
  }
  
  .btn-light{
    border-radius: 20px !important;
  }
  
  .btn-intro{
    margin-left: 0px;
    margin: 16px 16px 0px 16px;
    border-radius: 20px !important;
  }

  .conButtonsPc{
    display: none;
  }

  .conButtons{
    display: block;
    margin-top: 8%;
  }

  .intro h3{
    font-size: 20px;
  }
  .devGuy img{
    width: 95%;
    padding-top: 8%;
  }
}