.pt-30 {
  padding-top: 20px;
}

.footer{
  border-top: #FFD523 solid 1px;
}

a:hover {
  text-decoration: none;
  color: #FFD523;
}

a{
  color: inherit;
}

.fabs {
  font-size: 25px;
  padding-right: 15px;
}

.credit{
  text-align: center;
  padding-bottom: 3rem;
}

@media (max-width: 992px) {
  h3 {
    font-size: 18px;
  }
  .p-5 {
    /* padding: 2rem !important; */
  }
}
@media (max-width: 830px) {
  .pt-60 {
    padding-top: 60px;
  }
  .col-md-3 {
    padding-right: 8px;
    padding-left: 8px;
  }
  h3 {
    font-size: 16px;
  }
  .fabs {
    font-size: 22px;
    padding-right: 15px;
  }
}
@media (max-width: 768px) {
  .credit{
    font-weight: normal;
    font-size: smaller;
  }
  .pt-60 {
    padding-top: 30px;
  }
  .fabs {
    font-size: 20px;
    padding-right: 13px;
  }
}
@media (max-width: 475px) {
  h3 {
    font-size: 30px;
    padding-top: 30px;
  }
}