.subheading{
  text-align: center;
  font-weight: 600;
}

.arrowsphone{
  margin-top: 3%;
  text-align: center;
  display: none;
}

.flex-corousel{
  display: flex;
}

.slide{
  box-shadow: -3px -3px 1px #6c63ff;
}

.left-arrow{
  height: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: larger;
}

.right-arrow{
  height: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  font-size: larger;
}

.fa-arrow-left{
  font-size: 200%;
  margin-right: 40px;
}

.fa-arrow-right{
  font-size: 200%;
  margin-left: 40px;
}

@media screen and (max-width: 768px) {
  .subheading{
    font-size: 21px;
  }

  .arrowsphone{
    display: block;
  }

  .left-arrow{
    margin-left: 0rem;
  }

  .arrowpc{
    display: none;
  }
  
  .right-arrow{
    margin-right: 0rem;
  }

  .fa-arrow-left{
    font-size: 100%;
    margin-right: 10px;
  }
  
  .fa-arrow-right{
    font-size: 100%;
    margin-left: 10px;
  }
}